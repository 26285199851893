.generic-treetable__handle-icon,
.generic-treetable__action-menu-trigger,
.generic-treetable__action-menu-no-trigger {
    opacity: .09
}

.generic-treetable__handle_cell {
    cursor: grab;
}

.generic-treetable__row--hover .generic-treetable__handle-icon,
.generic-treetable__row--hover .generic-treetable__action-menu-trigger {
    opacity: 1;
}

.generic-treetable__prefix_cell,
.generic-treetable__dirty_state_cell {
    text-align: center;
}

.generic-treetable__prefix_cell>div,
.webix_column.generic-treetable__prefix_cell .webix_cell_select {
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
    border-right: 0px;
    border-left: 0px;
}

.treetable__webix {
    margin-top: -1px;
}

.treetable__webix .webix_dtable_focused .webix_column>div.webix_cell_select,
.treetable__webix .webix_dtable_span.webix_selected,
.treetable__webix .webix_column>div.webix_cell_select,
.treetable__webix .webix_dt_editor {
    background-color: #a7b8d1;
    color: white;
}

.generic-treetable__drag_marker {
    width: 100%;
    height: 3px;
    background-image: linear-gradient(to right, transparent, #1ca1c1 3%, #1ca1c1);
    position: absolute;
    left: 0px;
    display: none;
}

.generic-treetable__drag_marker_middle {
    background-image: linear-gradient(to right, transparent, #1ca1c1 3%, #1ca1c1);
}

.generic-treetable__row--main {
    background-color: rgb(203, 220, 255);
}

.generic-treetable__row--sequel {
    background-color: rgb(230, 238, 255);
}

.generic-treetable__row--tv-show {
    background-color: rgb(211, 255, 212);
}