.navigation_menu {
  position: absolute;
  z-index: 8000;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  transition: width 0.3s ease-out;
  background: linear-gradient(to right, #d3d3d3 0%, #d3d3d3 55px, #ededed 55px, #ededed 100%);
}
.navigation_menu:hover {
  width: 300px;
  transition: width 0.1s ease-out;
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.2);
}
.navigation_menu #navigation_menu__positioner {
  position: absolute;
  left: 0;
  width: 600px;
  transition: all 0.3s ease;
}
.navigation_menu #navigation_menu__positioner.show-sub-menu {
  left: -300px;
}
.navigation_menu__content {
  width: 300px;
  float: left;
}
.navigation_menu__content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation_menu__content ul > li.with-hover:hover {
  background: rgba(0, 0, 0, 0.05);
}
.navigation_menu__content .item {
  color: #202834;
  text-decoration: none;
  position: relative;
  display: block;
  padding: 1em;
  padding-left: 71px;
  cursor: pointer;
}
.navigation_menu__content .active {
  color: #F09D39;
}
.navigation_menu__content .icon {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  text-align: center;
  visibility: visible;
  transition: all 0.3s ease;
}
.navigation_menu__back-item {
  background: rgba(0, 0, 0, 0.1);
}
.navigation_menu__back-item .back-button-box {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  text-align: center;
}
.navigation_menu__back-item .back-button:hover .icon {
  display: block;
  position: absolute;
  top: 0;
  left: -55px;
  width: 55px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  text-align: center;
  visibility: hidden;
  transition: all 0.3s ease;
}
.navigation_menu__back-item .back-button .back-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 55px;
  width: 55px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  text-align: center;
  visibility: hidden;
  transition: all 0.3s ease;
}
.navigation_menu__back-item .back-button:hover .back-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  text-align: center;
  visibility: visible;
  transition: all 0.3s ease;
}
.navigation_menu__children {
  padding-left: 75px;
}
